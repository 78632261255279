<template lang="pug">
div
  logout(v-model="showLogout", @click="showLogout = true")
  v-app-bar(fixed outlined elevate-on-scroll)
    v-tabs.ml-10(style="margin-bottom: -2px")
        v-tab.no-design(to="/admin" exact) Home
        v-tab.no-design(to="/admin/edit") Announcements
        v-tab.no-design(to="/admin/books") Manage Books
        v-tab.no-design(to="/admin/users") Manage Users
        v-tab.no-design(to="/admin/notifications") Validate Users
        v-tab.no-design(to="/admin/course-references") Course References
        v-tab.no-design(to="../../user/home") User Module
    v-menu(transition="slide-x-transition", offset-y, bottom, right)
              template(v-slot:activator="{ on, attrs }")
                v-btn(
                  v-bind="attrs",
                  v-on="on",
                  depressed,
                  fab,
                  color="transparent",
                  x-small
                ).mr-10
                  v-avatar.text-center(size="32", v-if="!$store.state.userToken.picture")
                    v-icon(large, color="grey") mdi-account-circle
                  v-avatar.justify-content-end(size="32", v-else)
                    img(:src="$store.state.userToken.picture")
              v-list
                v-list-item(
                  dense,
                  :to="{ path: '/user/profile' }",
                  replace
                ).no-design
                  //- Profile
                  v-list-item-icon
                    v-icon.ml-1 mdi-account
                  v-list-item-title.no-design.ml-n4(dense) Profile

                v-list-item(
                  dense,
                  @click.stop="showLogout = true"
                )
                  //- Survey Form Logout
                  v-list-item-icon
                    v-icon.ml-1 mdi-logout
                  v-list-item-title.ml-n4(dense) Logout
    //- v-progress-linear(
    //-     :active="loading"
    //-     :indeterminate="loading"
    //-     absolute
    //-     bottom
    //-     color="primary"
    //-     )

  router-view(:key="$route.path").mt-12.pt-1
</template>

<script>
import Logout from "./Logout.vue";

export default {
  components: { Logout },
  data() {
    return {
      showLogout: false,
      loading: true,
    };
  },
  async created() {
    this.$store.dispatch("getPasswordAndFormLink");
  },
};
</script>

<style>
.v-tab {
  text-transform: none !important;
}
.no-design {
  text-decoration: none !important;
}
</style>
